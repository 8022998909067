<template>
  <div class="max-w-lg">
    <Swiper
        :speed="500"
        :spaceBetween="1"
        :pagination="{ clickable: true }"
        :autoplay="autoplayOptions"
        :loop="true"
        :grab-cursor="true"
        :loop-fill-group-with-blank="true"
        :modules="modules"
    >
      <SwiperSlide v-for="(item, index) in items" :key="index">
        <slot name="item" :item="{ ...item, index }" :index="index"/>
      </SwiperSlide>
    </Swiper>
  </div>
</template>
<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';

defineProps({
  items: {
    type: Array,
    default: () => [],
  },
});

const modules = [Pagination, Autoplay];
const autoplayOptions = {
  delay: 7000,
  disableOnInteraction: false,
};

</script>
<style lang="postcss">
.swiper-pagination-bullet {
  @apply border-[1px] border-primary bg-transparent opacity-100;
}
.swiper-pagination-bullet-active {
  @apply bg-primary;
}
</style>
