<template>
  <Head :title="headTitle ?? title" />

  <div class="grid w-screen h-screen max-h-screen grid-cols-1 lg:grid-cols-2">
    <div class="max-w-[27.5rem] c-custom-width__fill-available px-6 flex flex-col mx-auto">
      <div class="flex items-center justify-center h-full">
        <div class="flex flex-col items-center w-full">
          <AuthenticationCardLogo class="mx-auto mb-12 lg:w-fit" />
          <div class="w-full font-primary">
            <h1 class="mb-10 text-2xl font-bold text-center">{{ props.title }}</h1>
            <p v-if="props.text" class="mb-10 text-center">{{ props.text }}</p>
            <slot />
          </div>
        </div>
      </div>
    </div>
    <img
      src="/images/backgrounds/reset_password_top_right.png"
      alt="design element"
      class="absolute bottom-0 left-0 block w-24 h-auto rotate-180 -z-10 lg:hidden"
    />
    <div class="rounded-l-[31.25rem] h-full pl-10 hidden lg:block bg-[#F5F7FA80]">
      <div
        class="rounded-l-[31.25rem] h-full bg-[#F5F7FA] flex flex-col items-center gap-4 justify-center w-full"
      >
        <img src="/images/login_image.svg" alt="People working" />
        <Carousel :items="carouselItems">
          <template #item="{ item }">
            <div class="px-10 pb-10">
              <h2 class="text-2xl font-bold text-center font-primary">{{ item.title }}</h2>
              <p class="mt-4 text-center">{{ item.text }}</p>
            </div>
          </template>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Head } from '@inertiajs/vue3';

import AuthenticationCardLogo from '../Components/AuthenticationCardLogo.vue';
import Carousel from '../Components/Carousel.vue';

const props = defineProps({
  title: String,
  headTitle: String,
  text: {
    type: String,
    default: undefined,
  },
});

const carouselItems = [
  {
    title: 'Focus on what matters',
    text: 'Focus on your meeting. We wrote it down for you!',
  },
  {
    title: 'Relevant insights',
    text: 'Get access to a complete report of your meeting, including tips and suggestions!',
  },
];
</script>

<style scoped lang="scss">
.c-custom-width__fill-available {
  width: -webkit-fill-available;
}
</style>
